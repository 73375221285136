.about-main {
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.5;
  user-select: none;

  width: 70%;

  top: 50%;
  left: 50%;
}
