.whole-page {
  height: 100%;
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/*
background-size: cover 와 비슷함. (HTML 요소 or 비디오와 작동); /* */
