@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
  margin: 0;
  padding: 0;
}

.whole-page {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;

  filter: brightness(38%);
}

.content {
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quote-div h1,
.quote-div h3 {
  font-family: pretendard;
  position: absolute;

  transform: translate(-50%, -50%);
  left: 50%;
}

.quote-div {
  position: relative;
  align-items: center;
  justify-content: center;

  width: 33%;
  height: 25%;
}

.first-quote {
  border-bottom: solid white 2px;
  width: 35%;

  text-align: center;

  top: 10%;
}

.second-quote {
  top: 37%;
  width: 80%;

  text-align: center;

  font-weight: bold;
  font-size: 35px;
}

.third-quote {
  bottom: 15%;
  width: 120%;
  text-align: center;

  font-weight: bold;
  font-size: 35px;
}
