@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

.whole-page {
  height: 100%;
}
.page3-div {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

.contact-phrase {
  color: black;
  font-family: pretendard;
  font-size: 22px;
  font-weight: 700;
  user-select: none;

  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, 50%);
}
